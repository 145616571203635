








import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin'

@Component({
  components: {
    LinkTabs,
  },
})
export default class Depository extends Mixins(PermissionsMixin) {
  private get canViewMaterials () {
    return this.hasSomePermissions([this.Permission.DEPOSITORY_MATERIALS_VIEW, this.Permission.DEPOSITORY_MATERIALS_EDIT])
  }

  private get canViewManuals () {
    return this.hasSomePermissions([this.Permission.DEPOSITORY_MANUAL_VIEW, this.Permission.DEPOSITORY_MANUAL_EDIT])
  }

  private mounted () {
    if (this.$route.name === 'manager.bank.depository') {
      if (this.canViewMaterials) {
        this.$router.replace({ name: 'manager.bank.depository.courses' })
      } else if (this.canViewManuals) {
        this.$router.replace({ name: 'manager.bank.depository.manual' })
      } else {
        this.$router.replace({ name: 'manager' })
      }
    }
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'База знаний',
    }
  }
}
